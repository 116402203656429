export const DEFAULT_CITY = 'madrid';
export const DEFAULT_ROOMS = '1';
export const MAX_ROOMS = 15;
export const MQ_DESKTOP_MIN_WIDTH = 1101;
export const MQ_TABLET_MAX_WIDTH = MQ_DESKTOP_MIN_WIDTH - 1;
export const MQ_TABLET_MIN_WIDTH = 701;
export const MQ_MOBILE_MAX_WIDTH = MQ_TABLET_MIN_WIDTH - 1;
export const MIN_LENGTH_AUTOCOMPLETER = 1;
export const HOMECARD_IMAGE_SIZES = {
  homecardHidpi: '640w',
  homecard: '320w'
};
export const FEATURED_CITIES = [
  'madrid',
  'berlin',
  'brussels',
  'barcelona',
  'milan'
];

export const SEO_LINK_CITIES_KEYS = [
  'a_coruna',
  'alcala_de_henares',
  'alicante',
  'almeria',
  'amsterdam',
  'athens',
  'barcelona',
  'berlin',
  'bilbao',
  'bologna',
  'brussels',
  'budapest',
  'cadiz',
  'cartagena',
  'coimbra',
  'cologne',
  'copenhagen',
  'cordoba',
  'dubai',
  'dublin',
  'dusseldorf',
  'edinburgh',
  'florence',
  'frankfurt',
  'galway',
  'gdansk',
  'genoa',
  'ghent',
  'glasgow',
  'granada',
  'graz',
  'hamburg',
  'huelva',
  'istanbul',
  'jaen',
  'katowice',
  'kaunas',
  'krakow',
  'las_palmas_de_gran_canaria',
  'leipzig',
  'liege',
  'lille',
  'lisbon',
  'ljubljana',
  'lodz',
  'london',
  'lublin',
  'luxembourg',
  'lyon',
  'madrid',
  'malaga',
  'manchester',
  'marseille',
  'milan',
  'montpellier',
  'munich',
  'murcia',
  'naples',
  'nice',
  'nicosia',
  'nottingham',
  'oviedo',
  'padua',
  'palma_de_mallorca',
  'pamplona',
  'paris',
  'perugia',
  'porto',
  'poznan',
  'prague',
  'riga',
  'rome',
  'rotterdam',
  'saint_julians',
  'salamanca',
  'san_sebastian',
  'santa_cruz_de_tenerife',
  'santander',
  'santiago_de_compostela',
  'setubal',
  'seville',
  'sofia',
  'stockholm',
  'stuttgart',
  'tallinn',
  'thessaloniki',
  'toulouse',
  'trento',
  'turin',
  'valencia',
  'valladolid',
  'valletta',
  'vienna',
  'vigo',
  'vilnius',
  'warsaw',
  'wroclaw',
  'zaragoza',
  'zurich',
  'brescia',
  'cagliari'
];

export const TOP_CITIES = {
  madrid: 9341,
  barcelona: 5708,
  rome: 4688,
  milan: 5423,
  lisbon: 3707,
  berlin: 2705,
  valencia: 3967,
  brussels: 2933,
  dublin: 1238,
  london: 3383,
  paris: 1696
};

export const LANDING_SECTIONS = {
  hero: 'hero',
  featureBox: 'featureBox',
  quote: 'quote',
  guarantee: 'guarantee',
  propertyType: 'propertyType',
  citiesShowcase: 'citiesShowcase',
  cardTestimonialQuote: 'cardTestimonialQuote',
  faq: 'faq',
  homeFooter: 'homeFooter',
  footer: 'footer',
  ourGuarantee: 'ourGuarantee',
  findYourHome: 'findYourHome',
  depositProtection: 'depositProtection',
  topHomecards: 'topHomecards'
};

export const PUBLIC_ASSETS_LINK =
  'https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/';

export default {
  DEFAULT_CITY,
  MQ_DESKTOP_MIN_WIDTH,
  MQ_TABLET_MAX_WIDTH,
  MQ_TABLET_MIN_WIDTH,
  MQ_MOBILE_MAX_WIDTH,
  HOMECARD_IMAGE_SIZES,
  MIN_LENGTH_AUTOCOMPLETER,
  FEATURED_CITIES,
  TOP_CITIES,
  LANDING_SECTIONS,
  PUBLIC_ASSETS_LINK
};

export const SECTION_HEADING_THEME_DARK = 'dark';
export const SECTION_HEADING_TYPE_NONE = 'none';
