import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { trans, useSoyuzLocales } from '@spotahome/soyuz/client';
import {
  Colors,
  MediaQuery,
  TrustpilotWidget,
  useCitiesContext
} from '@spotahome/ui-library';
import HistoricalMetricsRedesign from '@spotahome/ui-library/src/tenant/components/HistoricalMetricsRedesign';

import HeroContainer from '@spotahome/landings-common/src/components/HeroContainer';
import HeaderContainer from '@spotahome/landings-common/src/components/HeaderContainer';

import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';

import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

import CityAutocompleter from './CityAutocompleter';

import './Hero.scss';

const { isMobileScreen, isPhabletScreen, isTabletScreen } = MediaQuery;

const Hero = ({ deviceType }) => {
  const [isDark, setIsDark] = useState(true);
  const { cities } = useCitiesContext();
  useEffect(() => {
    setIsDark(isMobileScreen() || isPhabletScreen() || isTabletScreen());
  }, []);
  const { current: locale } = useSoyuzLocales();

  return (
    <section>
      <HeroContainer>
        <div className="hero-tenant__header-wrapper">
          <HeaderContainer
            color={{
              foregroundColor: isDark ? 'dark' : 'light',
              logoColor: isDark ? 'dark' : 'light'
            }}
            tracking="ga-home-tenant"
          />
        </div>
        <div className="hero-tenant__wrapper">
          <div className={'hero-tenant__container'}>
            <div className="hero-tenant__info">
              <div className="hero-tenant__info-content">
                <h1 className="hero-tenant__info-title">
                  {trans('home.tenant.hero.title.experiment')}
                </h1>
                <p className="hero-tenant__info-subtitle">
                  {trans('home.tenant.hero.subtitle.experiment')}
                </p>
                <CityAutocompleter
                  cities={cities}
                  deviceType={deviceType}
                  className="hero-tenant__city-selector"
                  iconColor={Colors.medium}
                />
              </div>
            </div>
            <div className="hero-tenant__container-bottom">
              <TrustpilotWidget
                locale={locale}
                className="hero-tenant__trustpilot-wrapper"
              />
            </div>
          </div>
        </div>
      </HeroContainer>
      <HistoricalMetricsRedesign />
    </section>
  );
};

Hero.propTypes = {
  deviceType: PropTypes.oneOf(['smartphone', 'tablet', 'desktop']).isRequired
};

export default withInViewEvent(LANDING_SECTIONS.hero)(Hero);
