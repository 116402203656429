/* global dataLayer */

/* eslint-disable import/first */
import { useEffect } from 'react';
import NoSSR from 'react-no-ssr';
import Metrics from '@spotahome/soyuz-tracking';

import {
  ThemeProvider,
  CookiesProvider,
  ToastContainer,
  GoogleOneTap,
  SpottyCat,
  UserInfoProvider,
  generateFontPreload,
  TenantTypeProvider,
  AppBanner,
  CitiesProvider,
  Footer,
  DeviceProvider
} from '@spotahome/ui-library';
import { useCoreWebVitals } from '@spotahome/ui-library/src/hooks/useCoreWebVitals';
import {
  useSoyuzData,
  useSoyuzBrandConfig,
  useSoyuzExperiment
} from '@spotahome/soyuz/client';

import getGqlClient from '@spotahome/landings-common/src/graphql/client';

import { sendTenantActionEvent } from '@spotahome/landings-common/src/graphql/mutations/tenantActions';

import LoginSignupModal from '@spotahome/landings-common/src/components/LoginSignupModal';
import OurGuarantee from '@spotahome/ui-library/src/tenant/components/OurGuarantee';
import FAQSection from '@spotahome/ui-library/src/tenant/components/FAQSection';

import PropertyTypeSection from '@spotahome/landings-common/src/components/PropertyTypeSection';
import CitiesShowcase from '@spotahome/landings-common/src/components/CitiesShowcase';
import TestimonialsCarousel from '@spotahome/landings-common/src/components/TestimonialsCarousel/TestimonialsCarousel';
import OptInWrapper from '@spotahome/landings-common/src/components/OptInWrapper';
import getTopHomecards from '@spotahome/marketplace-common/src/graphql/queries/topHomecards';

import {
  getTenantFaq,
  getTenantStructuredFaq
} from '@spotahome/landings-common/src/utils/faq';

import FindYourHome from '@spotahome/ui-library/src/tenant/components/FindYourHome';

import {
  CustomDimensionsManager,
  constants
} from '@spotahome/ecommerce-tracking';

import '@spotahome/landings-common/src/scss/init.scss';

import Hero from './components/Hero';
import GuaranteeSection from './components/GuaranteeSection';
import LandlordSection from './components/LandlordSection';
import TopHomeCards from './components/TopHomeCards';

const getTenantStructuredFaqScript = trans => ({
  type: 'application/ld+json',
  innerHTML: JSON.stringify(getTenantStructuredFaq(trans))
});

const TOP_HOMECARDS_CITIES = [
  'madrid',
  'barcelona',
  'milan',
  'rome',
  'valencia',
  'brussels',
  'lisbon',
  'berlin'
];

const LandingPage = () => {
  const { [LandingPage.NAME]: requestedData } = useSoyuzData();
  const { isPartner } = useSoyuzBrandConfig();

  const isTopHomecardsExperiment =
    useSoyuzExperiment('TopHomecardsExperiment').get('behaviour') === 'variant';

  const { userId, initialCookie, isSafari, deviceType, topHomecards } =
    requestedData;

  useCoreWebVitals();

  useEffect(() => {
    Metrics.actions.configureClient(async ({ actionId, payload }) => {
      try {
        await sendTenantActionEvent(getGqlClient())({
          actionId,
          payload
        });
      } catch (e) {
        console.log('error sending sendTenantActionEvent:', e);
      }
    });
  }, []);

  useEffect(() => {
    if (userId && typeof dataLayer !== 'undefined') {
      dataLayer.push({ userId });
    }
  }, [userId]);
  return (
    <CookiesProvider initialCookie={initialCookie}>
      <ThemeProvider flags={{ newButtons: true }}>
        <UserInfoProvider>
          <DeviceProvider deviceType={deviceType}>
            <CitiesProvider>
              {!isPartner ? <SpottyCat /> : null}
              <AppBanner source="home" deviceType={deviceType} />
              <ToastContainer />
              {!userId && <GoogleOneTap />}
              <TenantTypeProvider>
                <div data-browser={isSafari ? 'safari' : 'other'}>
                  <Hero deviceType={deviceType} />
                  {!isPartner && (
                    <>
                      {isTopHomecardsExperiment && (
                        <TopHomeCards topHomecards={topHomecards} />
                      )}
                      <GuaranteeSection />
                      <TestimonialsCarousel />
                      <OurGuarantee />
                      <PropertyTypeSection deviceType={deviceType} isRedesign />
                      <LandlordSection />
                      <CitiesShowcase deviceType={deviceType} isRedesign />
                      <FAQSection questions={getTenantFaq()} isRedesign />
                      <FindYourHome />
                      <Footer theme="beige" />
                      <OptInWrapper />
                    </>
                  )}
                  <NoSSR>
                    <LoginSignupModal />
                  </NoSSR>
                </div>
              </TenantTypeProvider>
            </CitiesProvider>
          </DeviceProvider>
        </UserInfoProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
};

LandingPage.requestAllData = async (req, _, { graphqlClient }) => {
  try {
    const { locale, userId } = req;
    const cdManager = CustomDimensionsManager();
    cdManager.setCommonCustomDimensions({
      userId,
      locale,
      pageType: constants.TRACKING_PAGE_TYPES.HOMEPAGE
    });

    const isSafari =
      req.device.info && req.device.info.client
        ? req.device.info.client.name.toLowerCase().includes('safari')
        : false;

    let topHomeCardsResponse = null;

    try {
      topHomeCardsResponse = await getTopHomecards(graphqlClient)({
        locale,
        cities: TOP_HOMECARDS_CITIES
      });
    } catch (err) {
      req.logger.warn('Failed to fetch top homecards:', { err });
      topHomeCardsResponse = null;
    }

    return {
      headers: {
        scripts: [
          { innerHTML: cdManager.flush() },
          getTenantStructuredFaqScript(req.trans)
        ],
        links: [...generateFontPreload(req.brandConfig)]
      },
      data: {
        userId: req.userId,
        deviceType: req.device.type,
        initialCookie: req.headers.cookie,
        isSafari,
        topHomecards: topHomeCardsResponse
      }
    };
  } catch (err) {
    req.logger.error('Landing page error', { err });
  }

  return {
    headers: {
      links: [...generateFontPreload(req.brandConfig)]
    },
    data: {
      userId: null,
      topHomecards: null
    }
  };
};

LandingPage.NAME = 'LandingPage';

export default LandingPage;
