import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { getPagePath, trans } from '@spotahome/soyuz/client';
import { useEcommerceManagerFactory } from '@spotahome/ecommerce-tracking';

import { HomecardFormatter } from '@spotahome/ui-library/src/tenant/utils/images';
import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';
import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

import { getMonthlyPrice } from '@spotahome/marketplace-common/src/utils/homecards';
import Homecard from '@spotahome/marketplace-common/src/components/Homecard';
import { getCurrencySymbol } from '@spotahome/marketplace-common/src/utils/CurrencyFormatter';

import './TopHomeCards.scss';

const TopHomeCards = ({ topHomecards }) => {
  const ecommerceManager = useEcommerceManagerFactory();

  const getFlagEmoji = countryIsoCode => {
    return String.fromCodePoint(
      ...[...countryIsoCode.toUpperCase()].map(
        char => 0x1f1e6 + char.charCodeAt(0) - 65
      )
    );
  };

  const getTitle = location => {
    if (!location) return '';
    return `${location.cityName} ${getFlagEmoji(
      location.country.countryIsoCode
    )}`;
  };

  const homecards = useMemo(() => {
    if (!topHomecards) return [];
    return topHomecards
      .map(topHomecard => {
        if (topHomecard.homecard) {
          return {
            ...topHomecard.homecard,
            title: getTitle(topHomecard.location),
            location: topHomecard.location,
            city: topHomecard.location.cityName,
            photos: HomecardFormatter.getPhotos(
              topHomecard.homecard.photoIds,
              []
            )[0],
            photoUrls: HomecardFormatter.getPhotoUrls(
              topHomecard.homecard.photoIds
            ),
            monthlyPrice: getMonthlyPrice(topHomecard.homecard.displayPrice),
            offers: []
          };
        }
        return null;
      })
      .filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topHomecards]);

  const getLink = location => {
    return getPagePath('marketplace.free-search', {
      params: { locationString: location.id }
    });
  };

  const trackHomecardClick = (position, cardData, cityId) => {
    ecommerceManager.trackLandingPageHomecardClick(cardData, position, cityId);
  };

  return (
    <section className="top-homecards">
      <h2 className="top-homecards__title">
        {trans('topHomecards.mobile.title')}
      </h2>
      <ul className="top-homecards__list">
        {homecards.map((homecard, index) => (
          <li
            className="top-homecards__list-item"
            data-homecard-scroll={homecard.id}
            key={homecard.id}
          >
            <Homecard
              isShowStatus
              data={homecard}
              currency={getCurrencySymbol(homecard)}
              hasLazyLoad
              showAvailableFromYear={false}
              withTransparentBackground
              pageType="landing_page"
              section="top_homecards_cities"
              linkTo={getLink(homecard.location)}
              onClick={() =>
                trackHomecardClick(index, homecard, homecard.location.cityName)
              }
              showFavorite={false}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

TopHomeCards.propTypes = {
  topHomecards: PropTypes.array.isRequired
};

export default withInViewEvent(LANDING_SECTIONS.guarantee)(TopHomeCards);
