import gql from 'graphql-tag';

const getFullQuery = searchQueries => {
  const cityQueries = searchQueries.map(query => query.cityQuery).join('\n');
  const searchSubqueries = searchQueries
    .map(query => query.searchQuery)
    .join('\n');

  return gql`
    query TopHomecards($locale: CityLocale!) {
      ${cityQueries}
      ${searchSubqueries}
    }
  `;
};

const getQuery = cityId => ({
  cityQuery: `
    city${cityId}: city(cityId: "${cityId}", enabled: true) {
      id
      cityName(locale: $locale)
      country {
        countryIsoCode
        countryId
      }
    }
  `,
  searchQuery: `
    search${cityId}: search(cityId: "${cityId}", filters: {}, limit: 1) {
      homecards(locale: $locale) {
        id
        checked
        currencyIsoCode
        firstAvailableDate
        isInstantBooking
        noSecurityDeposit
        mainPhotoUrl
        type
        url
        features
        area
        allowsPermanentContract
        isBestChoice
        photoIds
        displayPrice
        billsIncluded
      }
    }
  `
});

const getTopHomecards =
  graphqlClient =>
  async ({ locale, cities }) => {
    const searchQueries = cities.map(city => getQuery(city));
    const query = getFullQuery(searchQueries);

    try {
      const response = await graphqlClient.query({
        query,
        fetchPolicy: 'cache-first',
        variables: { locale }
      });

      const results = cities.map(city => {
        const cityData = response?.data?.[`city${city}`];
        const homecardData =
          response?.data?.[`search${city}`]?.homecards[0] || null;

        return {
          location: cityData,
          homecard: homecardData
        };
      });

      return results;
    } catch (error) {
      console.error(`Error fetching top homecards:`, error);
      return cities.map(city => ({
        city,
        homecard: null
      }));
    }
  };

export default getTopHomecards;
